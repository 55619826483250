// src/components/CompetitiveAdvantageSection.js

import React from 'react';
import styled from 'styled-components';
import { Section, HorizontalLine, SectionTitle } from './common/Sections';
import { advantageData } from './data/advantageData';

const CompetitiveAdvantageSection = ({ title }) => {
  return (
    <Section id="advantage">
      <ContentBox>
        <Header>
          <SectionTitle>For Players </SectionTitle>
          <HorizontalLine />
        </Header>
        <Grid>
          {advantageData.map((item, index) => (
            <AdvantageCard
              key={index}
              data-aos="fade-up"
              data-aos-delay={index * 100}
            >
              <IconWrapper>{item.icon}</IconWrapper>
              <CardTitle>{item.title}</CardTitle>
              <CardDescription>{item.description}</CardDescription>
            </AdvantageCard>
          ))}
        </Grid>
      </ContentBox>
    </Section>
  );
};

export default CompetitiveAdvantageSection;

// Styled Components

const ContentBox = styled.div`
  max-width: 1200px;
  margin: 0 auto 4rem auto;
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.darkBackground};
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  box-shadow: ${({ theme }) => theme.shadows.medium};
  border: 1px solid ${({ theme }) => theme.colors.accents[2]};


  &:hover {
    transform: translateY(-0.5rem);
    box-shadow: ${({ theme }) => theme.shadows.medium};

  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 1.5rem;
    margin-bottom: 2rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 1rem;
    margin-bottom: 2rem;
  }
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.lg};

`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(480px, 1fr));
  gap: ${({ theme }) => theme.spacing.lg};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    gap: ${({ theme }) => theme.spacing.md};
    grid-template-columns: 1fr;
  }
`;

const AdvantageCard = styled.div`
  background-color: ${({ theme }) => theme.colors.darkBackground};
  padding: ${({ theme }) => theme.spacing.lg};
  border-radius: ${({ theme }) => theme.sizes.borderRadius};
  text-align: center;
  box-shadow: ${({ theme }) => theme.shadows.small};
  transition: ${({ theme }) => theme.transitions.default};
  border: 1px solid ${({ theme }) => theme.colors.accents[2]};

  &:hover {
    transform: translateY(-0.5rem);
    box-shadow: ${({ theme }) => theme.shadows.medium};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: ${({ theme }) => theme.spacing.md};
  }
`;

const IconWrapper = styled.div`
  font-size: 3rem;
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  transition: color 0.3s ease, transform 0.3s ease;

  ${AdvantageCard}:hover & {
    color: ${({ theme }) => theme.colors.accents[0]};
    transform: scale(1.1);
  }
`;

const CardTitle = styled.h3`
  font-size: ${({ theme }) => theme.fontSizes.lg};
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.secondary};
`;

const CardDescription = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.md};
  line-height: 1.6;
 color: ${({ theme }) => theme.colors.secondaryBackground};
`;
