// Import necessary modules
import styled, { css, keyframes } from 'styled-components';
import { media } from '../../../utils/media';
import { FaSpinner } from 'react-icons/fa';
import { Modal, Form, Button } from 'react-bootstrap';

// Shared Styles and Animations

// Keyframes for the spinner animation
const spin = keyframes`
  to { transform: rotate(360deg); }
`;

// Shared button styles
const buttonStyles = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  font-weight: 500;
  border: none;
  border-radius: ${({ theme }) => theme.spacing.xs};
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:focus {
    outline: 2px solid ${({ theme }) => theme.colors.focusOutline};
    outline-offset: 2px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

// Card Component

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(100% - ${({ theme }) => theme.spacing.md});
  margin: ${({ theme }) => theme.spacing.md} 0;
  border-radius: ${({ theme }) => theme.spacing.sm};
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  box-shadow: ${({ theme }) => theme.shadows.medium};
  overflow: hidden;
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: translateY(-${({ theme }) => theme.spacing.xs});
    box-shadow: ${({ theme }) => theme.shadows.large};
  }

  // Responsive grid settings
  ${media.desktop`
    max-width: calc(50% - ${({ theme }) => theme.spacing.md});
  `}

  ${media.tablet`
    max-width: 100%;
  `}
`;

// Checkbox Container

export const CheckboxContainer = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing.sm};
  left: ${({ theme }) => theme.spacing.sm};
  z-index: 2;

  input[type='checkbox'] {
    width: ${({ theme }) => theme.spacing.md};
    height: ${({ theme }) => theme.spacing.md};
    cursor: pointer;
  }
`;

// Tooltip Components

export const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const TooltipText = styled.span`
  visibility: hidden;
  opacity: 0;
  width: 12rem;
  background-color: ${({ theme }) => theme.colors.tooltipBackground};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  border-radius: ${({ theme }) => theme.spacing.xs};
  padding: ${({ theme }) => theme.spacing.xs};
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.2s ease;

  /* Tooltip arrow */
  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.xs} 0;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.tooltipBackground} transparent transparent;
  }

  /* Show the tooltip on hover */
  ${TooltipWrapper}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;

// Thumbnail Container

export const ThumbnailContainer = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.sm} 0 0;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
`;

// Styled Video

export const StyledVideo = styled.video`
  width: 100%;
  height: auto;
  display: block;
  background-color: #000;
`;

// Play Button

export const PlayButton = styled.button`
  ${buttonStyles}
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.6);
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.lg};
  border-radius: 50%;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }
`;

// Purchase Overlay

export const PurchaseOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: 600;
  text-align: center;
  padding: ${({ theme }) => theme.spacing.sm};
`;

// Download Icon Container

export const DownloadIconContainer = styled.button`
  ${buttonStyles}
  position: absolute;
  bottom: ${({ theme }) => theme.spacing.sm};
  right: ${({ theme }) => theme.spacing.sm};
  background: rgba(0, 0, 0, 0.6);
  color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  padding: ${({ theme }) => theme.spacing.sm};
  z-index: 2;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }
`;

// Spinner Icon

export const SpinnerIcon = styled(FaSpinner)`
  animation: ${spin} 1s linear infinite;
  color: ${({ theme }) => theme.colors.white};
`;

// Content Container

export const Content = styled.div`
  padding: ${({ theme }) => theme.spacing.md};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

// Title and Button Group

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.sm};
`;

export const Title = styled.h3`
  margin: 0 0 ${({ theme }) => theme.spacing.xs} 0;
  font-size: ${({ theme }) => theme.fontSizes.lg};
  color: ${({ theme }) => theme.colors.title};
  font-weight: ${({ isPurchased }) => (isPurchased ? '600' : '400')};
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs};
`;

// Action Buttons

const ActionButton = styled.button`
  ${buttonStyles}
  background-color: ${({ bgColor, theme }) => theme.colors[bgColor]};
  color: ${({ theme }) => theme.colors.white};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ hoverColor, theme }) => theme.colors[hoverColor]};
  }
`;

export const EditButton = styled(ActionButton).attrs({
  bgColor: 'teal',
  hoverColor: 'darkBackground',
})``;

export const TrimButton = styled(ActionButton).attrs({
  bgColor: 'orange',
  hoverColor: 'darkBackground',
})``;

export const DeleteButton = styled(ActionButton).attrs({
  bgColor: 'error',
  hoverColor: 'darkBackground',
})``;

// Description

export const Description = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.sm};
  color: ${({ theme }) => theme.colors.textSecondary};
  margin-bottom: ${({ theme }) => theme.spacing.md};
  line-height: 1.5;
`;

// Progress Components

export const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.sm};
`;

export const ProgressBar = styled.div`
  flex-grow: 1;
  height: ${({ theme }) => theme.spacing.xs};
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: ${({ theme }) => theme.spacing.xxs};
  transition: width 0.3s ease;
`;

export const ProgressLabel = styled.span`
  margin-left: ${({ theme }) => theme.spacing.xs};
  font-size: ${({ theme }) => theme.fontSizes.xs};
`;

// Download Complete and Error Messages

export const DownloadCompleteLabel = styled.span`
  display: block;
  color: ${({ theme }) => theme.colors.success};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  margin-top: ${({ theme }) => theme.spacing.sm};
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  margin-top: ${({ theme }) => theme.spacing.sm};
`;

// Message for Unpurchased Videos

export const Message = styled.p`
  color: ${({ theme }) => theme.colors.warning};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  margin-top: ${({ theme }) => theme.spacing.sm};
`;

// Modal Components

export const StyledModal = styled(Modal)`
  .modal-content {
    background-color: ${({ theme }) => theme.colors.primaryBackground};
    border-radius: ${({ theme }) => theme.spacing.sm};
    overflow: hidden;
  }
`;

export const StyledModalHeader = styled(Modal.Header)`
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  border-bottom: none;

  .modal-title {
    color: ${({ theme }) => theme.colors.textPrimary};
    font-size: ${({ theme }) => theme.fontSizes.lg};
  }

  .close {
    color: ${({ theme }) => theme.colors.textSecondary};
    opacity: 1;
  }
`;

export const StyledModalBody = styled(Modal.Body)`
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  color: ${({ theme }) => theme.colors.textPrimary};
`;

export const StyledModalFooter = styled(Modal.Footer)`
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  border-top: none;
`;

export const StyledTrimModalHeader = styled(Modal.Header)`
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
  padding: ${({ theme }) => theme.spacing.lg} ${({ theme }) => theme.spacing.md};

  .modal-title {
    color: ${({ theme }) => theme.colors.darkBackground};
    font-size: ${({ theme }) => theme.fontSizes['2xl']};
    font-weight: bold;
  }
`;

export const StyledTrimModalBody = styled(Modal.Body)`
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  padding: ${({ theme }) => theme.spacing.xl} ${({ theme }) => theme.spacing.lg};

  .video-trimmer-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.md};
  }
`;

// Form Control

export const StyledFormControl = styled(Form.Control)`
  background-color: ${({ theme }) => theme.colors.inputBackground};
  color: ${({ theme }) => theme.colors.textPrimary};
  border: 1px solid ${({ theme }) => theme.colors.border};

  &:focus {
    background-color: ${({ theme }) => theme.colors.inputFocusBackground};
    border-color: ${({ theme }) => theme.colors.focusBorder};
    box-shadow: none;
  }
`;

// Modal Buttons

export const StyledSaveButton = styled(Button)`
  ${buttonStyles}
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryDark};
  }
`;

export const StyledCancelButton = styled(Button)`
  ${buttonStyles}
  background-color: ${({ theme }) => theme.colors.lightBackground};
  color: ${({ theme }) => theme.colors.textPrimary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightBackgroundHover};
  }
`;

// Error Text

export const ErrorText = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  margin-top: ${({ theme }) => theme.spacing.sm};
`;

// Delete Confirmation Modal Components

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const ModalContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  padding: ${({ theme }) => theme.spacing.lg};
  border-radius: ${({ theme }) => theme.spacing.sm};
  width: 90%;
  max-width: 500px;
  text-align: center;
`;

export const ModalTitle = styled.h2`
  margin-bottom: ${({ theme }) => theme.spacing.md};
  font-size: ${({ theme }) => theme.fontSizes.xl};
`;

export const ModalBody = styled.p`
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  font-size: ${({ theme }) => theme.fontSizes.md};
`;

export const ModalActions = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.sm};
`;

export const ConfirmButton = styled(ActionButton).attrs({
  bgColor: 'error',
  hoverColor: 'errorDark',
})`
  min-width: 100px;
`;

export const CancelButton = styled(ActionButton).attrs({
  bgColor: 'lightBackground',
  hoverColor: 'lightBackgroundHover',
})`
  color: ${({ theme }) => theme.colors.textPrimary};
  min-width: 100px;
`;

// Additional Styles

export const EditNameText = styled.span`
  color: ${({ theme }) => theme.colors.link};
  cursor: pointer;
  margin-right: ${({ theme }) => theme.spacing.sm};
  font-size: ${({ theme }) => theme.fontSizes.sm};

  &:hover {
    text-decoration: underline;
  }
`;