// src/components/Policies/ReturnPolicy.js

import React, { useEffect } from 'react';

const ReturnPolicy = () => {
  useEffect(() => {
    if (!document.getElementById('termly-jssdk')) {
      const script = document.createElement('script');
      script.id = 'termly-jssdk';
      script.type = 'text/javascript';
      script.src = 'https://app.termly.io/embed-policy.min.js';
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  return (
    <div>
      <div
        name="termly-embed"
        data-id="f5a5bdd0-7cd2-40ed-bfa2-b28404f384ae"
      ></div>
    </div>
  );
};

export default ReturnPolicy;
