import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import api from '../../utils/api';
import VideoCard from '../Video/VideoCard';

import {media} from '../../utils/media'


const Dashboard = () => {
  const user = useSelector((state) => state.auth.user);
  const [purchasedVideos, setPurchasedVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [processId, setProcessId] = useState(null);
  const [progress, setProgress] = useState(0);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [feedbackContent, setFeedbackContent] = useState('');
  const [feedbackError, setFeedbackError] = useState('');
  const [feedbackSuccess, setFeedbackSuccess] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  // Extract processId from location state or query params
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const processIdFromUrl = queryParams.get('processId');
    const processIdFromState = location.state?.processId;
    const processIdToUse = processIdFromUrl || processIdFromState;

    console.log('Process ID to use:', processIdToUse);
    console.log('Location state:', location.state);
    console.log('Query params:', queryParams);
    console.log('Process ID from URL:', processIdFromUrl);

    if (processIdToUse) {
      setProcessId(processIdToUse);
      setIsProcessing(true);
    }
  }, [location]);



  // Fetch purchased videos for the logged-in user
const fetchPurchasedVideos = async () => {
  try {
    const response = await api.get(`/users/${user.id}/purchased-videos`);
    const videos = response.data;
    console.log('Fetched purchased videos:', videos);
    //sort the videos by date
    videos.sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate));
    setPurchasedVideos(videos);

    // Only update isProcessing if processId is not set
    if (!processId) {
      const anyInProgress = videos.some((video) => video.trimStatus === 'IN_PROGRESS');
      setIsProcessing(anyInProgress);
    }
    // If processId is present, checkVideoProcessingStatus is used for isProcessing
  } catch (err) {
    setError('Error fetching purchased videos. Please try again later.');
  } finally {
    setLoading(false);
  }
};


  // Function to check video processing status
  const checkVideoProcessingStatus = async (processId) => {
    try {
      const statusResponse = await api.get(`/videos/status/${processId}`);
      console.log('Video processing status:', statusResponse.data);
      const { status, progress, progressPercentage } = statusResponse.data;

      if (status === 'PROCESSING') {
        setIsProcessing(true);
        setProgress(progress); // Update progress
        setProgressPercentage(progressPercentage); // Update progress percentage
      } else if (status === 'PROCESSED') {
        fetchPurchasedVideos(); // Refresh purchased videos once processing is complete
        setIsProcessing(false);
        setProcessId(null);
      } else if (status === 'FAILED') {
        setError('Video processing failed. Please try again.');
        setIsProcessing(false);
        setProcessId(null);
      }
    } catch (error) {
      console.error('Error checking video processing status:', error);
      setError('Error checking video processing status.');
      setIsProcessing(false);
      setProcessId(null);
    }
  };

  useEffect(() => {
    if (user?.id) {
      fetchPurchasedVideos();
    }
  }, [user?.id, processId]);


  // Polling for the video processing status
  useEffect(() => {
    if (processId) {
      const intervalId = setInterval(() => {
        checkVideoProcessingStatus(processId);
      }, 5000); // Poll every 5 seconds

      return () => clearInterval(intervalId); // Clear the interval when component unmounts
    }
  }, [isProcessing, processId]);

  const handleStartProcessing = async () => {
    try {
      const response = await api.post('/videos/process', { /* request data */ });
      const { processId } = response.data;
      setProcessId(processId);
      setIsProcessing(true);
    } catch (error) {
      console.error('Error starting video processing:', error);
      setError('Error initiating video processing.');
    }
  }

  const handleFeedbackSubmit = async (e) => {
    e.preventDefault();
    if (!feedbackContent.trim()) {
      setFeedbackError('Please provide some feedback before submitting.');
      return;
    }
    try {
      const feedbackData = {
        user: { id: user.id }, // Include user.id here
        feedbackContent,
        timestamp: new Date().toISOString(),
      };
      await api.post('/feedback/submit', feedbackData);
      setFeedbackSuccess('Thank you for your feedback!');
      setFeedbackContent('');
      setFeedbackError('');
    } catch (err) {
      setFeedbackError('Failed to submit feedback. Please try again.');
    }
  };

  return (
    <DashboardContainer>
      {/* <DashboardNavBar /> */}
      <ContentWrapper>
        <DashboardHeader>
          <Title>Welcome Back, {user?.username}</Title>
          <SectionTitle>My Purchased Videos</SectionTitle>
        </DashboardHeader>

        {loading && <LoadingMessage>Loading your purchased videos...</LoadingMessage>}
        {error && <ErrorMessage>{error}</ErrorMessage>}

        {!loading && !error && isProcessing && (
          <ProcessingMessage>
            <Spinner /> Gettin' it ready for ya!. Progress: {progressPercentage}%
          </ProcessingMessage>
        )}

        {!loading && !error && purchasedVideos.length > 0 ? (
          <VideoListing>
            {purchasedVideos.map((video) => (
              <VideoCard key={video.id} video={video}>
                {video.trimStatus === 'IN_PROGRESS' ? (
                  <TrimmingMessage>
                    <Spinner /> Trimming in progress... Your video will be available soon.
                  </TrimmingMessage>
                ) : video.trimStatus === 'COMPLETED' ? (
                  <DownloadButton href={video.trimmedSignedUrl} download>
                    Download Trimmed Video
                  </DownloadButton>
                ) : video.trimStatus === 'FAILED' ? (
                  <ErrorMessage>Video trimming failed. Please try again.</ErrorMessage>
                ) : (
                  <ButtonContainer>
                  <StartProcessingButton onClick={handleStartProcessing}>
                    Process Video
                  </StartProcessingButton>
                  </ButtonContainer>

                )}
              </VideoCard>
            ))}
          </VideoListing>
        ) : (
          !loading && !isProcessing && <NoVideosMessage>No videos purchased yet.</NoVideosMessage>
        )}

        {/* Feedback Section */}
        <FeedbackFormContainer>
          <FeedbackTitle>Have Feedback? We’d love to hear from you!</FeedbackTitle>
          {feedbackSuccess && <FeedbackSuccessMessage>{feedbackSuccess}</FeedbackSuccessMessage>}
          {feedbackError && <FeedbackErrorMessage>{feedbackError}</FeedbackErrorMessage>}
          <form onSubmit={handleFeedbackSubmit}>
            <TextArea
                placeholder="Write your feedback here..."
                value={feedbackContent}
                onChange={(e) => setFeedbackContent(e.target.value)}
            />
            <SubmitButton type="submit">Submit Feedback</SubmitButton>
          </form>
        </FeedbackFormContainer>
      </ContentWrapper>
      {/* <Footer /> */}
    </DashboardContainer>
  );
};

export default Dashboard;


// Styled Components
const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.primaryBackground};
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

const TrimVideoButton = styled.button`
  padding: 12px 24px;
  background-color: ${({ theme }) => theme.colors.secondary};
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.accentOrange};
  }

  ${media.mobile(`
    padding: 10px 20px;
    font-size: 0.9rem;
  `)}
`;


const StartProcessingButton = styled.button`
  padding: 12px 24px;
  background-color: ${({ theme }) => theme.colors.accentOrange};
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  ${media.mobile(`
    padding: 10px 20px;
    font-size: 0.9rem;
  `)}
`;


  const ContentWrapper = styled.div`
  flex: 1;
  padding: 30px;
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  ${media.tablet(`
    padding: 20px;
    margin-top: 70px;
  `)}

  ${media.mobile(`
    padding: 15px;
    margin-top: 60px;
  `)}
`;


const DashboardHeader = styled.div`
  text-align: center;
  margin-bottom: 50px;

  ${media.tablet(`
    margin-bottom: 30px;
  `)}

  ${media.mobile(`
    margin-bottom: 20px;
  `)}
`;


const Title = styled.h1`
  font-size: 2.8rem;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 15px;

  ${media.tablet(`
    font-size: 2.2rem;
  `)}

  ${media.mobile(`
    font-size: 1.8rem;
  `)}
`;


const SectionTitle = styled.h2`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.accentBlueGray};
  margin-bottom: 50px;

  ${media.tablet(`
    font-size: 1.6rem;
    margin-bottom: 30px;
  `)}

  ${media.mobile(`
    font-size: 1.4rem;
    margin-bottom: 20px;
  `)}
`;


const VideoListing = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 25px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; /* Center the grid container */

  /* Adjust grid settings for responsiveness */
  ${media.tablet`
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  `}

  ${media.mobile`
    grid-template-columns: 1fr;
  `}
`;



const NoVideosMessage = styled.p`
  font-size: 1.3rem;
  color: #666;
  text-align: center;
  margin-top: 50px;

  ${media.tablet(`
    font-size: 1.1rem;
    margin-top: 30px;
  `)}

  ${media.mobile(`
    font-size: 1rem;
    margin-top: 20px;
  `)}
`;


const ErrorMessage = styled.p`
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;

  ${media.mobile(`
    font-size: 1.1rem;
  `)}
`;

const LoadingMessage = styled.p`
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.secondary};
  text-align: center;

  ${media.mobile(`
    font-size: 1.1rem;
  `)}
`;


const DownloadButton = styled.a`
  display: inline-block;
  padding: 12px 24px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  text-align: center;
  border-radius: 6px;
  text-decoration: none;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.colors.accentOrange};
  }

  ${media.mobile(`
    padding: 10px 20px;
    font-size: 0.9rem;
  `)}
`;


const TrimmingMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.accentOrange};
  font-size: 1.2rem;
  margin-top: 10px;

  ${media.mobile(`
    font-size: 1rem;
  `)}
`;


const Spinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid ${({ theme }) => theme.colors.accentOrange};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin-right: 10px;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;


const ProcessingMessage = styled.p`
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.accentBlueGray};
  text-align: center;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  ${media.mobile(`
    font-size: 1.1rem;
    margin-top: 20px;
  `)}
`;


// Feedback Form Styled Components
const FeedbackFormContainer = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  margin-top: 75px;
  text-align: center;
`;

const FeedbackTitle = styled.h3`
  font-size: 1.5rem;
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 15px;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 10px;
  border-radius: 5px;
  resize: none;
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  font-size: 1rem;
  margin-bottom: 15px;
`;

const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.colors.accentOrange};
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

const FeedbackSuccessMessage = styled.p`
  color: green;
  font-size: 1.1rem;
  margin-bottom: 15px;
`;

const FeedbackErrorMessage = styled.p`
  color: red;
  font-size: 1.1rem;
  margin-bottom: 15px;
`;
