// src/components/Video/VideoCard.js

import React, { useEffect, useState, useRef, useCallback } from 'react';
import { FaDownload, FaPlay, FaSpinner, FaCrop } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { downloadVideo, fetchUserPurchasedVideos } from '../../store/videoSlice';
import api from '../../utils/api';
import VideoTrimmer from './VideoTrimmer';
import CropModal from './CropModal'; // Newly created CropModal component
import { Modal, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  Card,
  CheckboxContainer,
  TooltipWrapper,
  TooltipText,
  ThumbnailContainer,
  StyledVideo,
  PlayButton,
  PurchaseOverlay,
  DownloadIconContainer,
  SpinnerIcon,
  Content,
  TitleContainer,
  Title,
  EditButton,
  DeleteButton,
  Description,
  ProgressWrapper,
  ProgressBar,
  ProgressLabel,
  DownloadCompleteLabel,
  ErrorMessage,
  Message,
  StyledModalHeader,
  StyledModalBody,
  StyledModalFooter,
  StyledTrimModalHeader,
  StyledTrimModalBody,
  ErrorText,
  ModalOverlay,
  ModalContainer,
  ModalTitle,
  ModalBody,
  ModalActions,
  CancelButton,
  ConfirmButton,
  ButtonGroup,
  TrimButton,
} from './styles/VideoCardStyles';

// Utility functions for formatting dates and times
export const formatDate = (uploadDate) => {
  const date = new Date(uploadDate);
  if (isNaN(date.getTime())) return 'Invalid Date';

  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(date);
};

export const formatDate2 = (uploadDate) => {
  const date = new Date(uploadDate);
  if (isNaN(date.getTime())) return 'Invalid Date';

  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(date);
};

export const formatDateExtractTime = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return 'Invalid Date';
  return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
};

export const formatDuration = (durationInSeconds) => {
  const minutes = Math.floor(durationInSeconds / 60);
  return `${minutes} min${minutes > 1 ? 's' : ''}`;
};

const VideoCard = ({ video, isSelected, onSelect }) => {
  const dispatch = useDispatch();
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const user = useSelector((state) => state.auth.user);
  const containerRef = useRef(null);
  const [newTitle, setNewTitle] = useState(video.title || '');
  const [showTrimModal, setShowTrimModal] = useState(false);
  const [showCropModal, setShowCropModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [currentPlatformConfig, setCurrentPlatformConfig] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const [croppedVideoUrl, setCroppedVideoUrl] = useState(null);
  const [cropError, setCropError] = useState(null);
  const [cropLoading, setCropLoading] = useState(false);
  

  // Download progress and status from Redux store
  const downloadProgress = useSelector((state) => state.video.downloadProgress[video.id]);
  const downloadStatus = useSelector((state) => state.video.downloadStatus[video.id]);

  const isDownloadInProgress = downloadStatus === 'loading';
  const isDownloadSuccessful = downloadStatus === 'success';
  const isDownloadFailed = downloadStatus === 'failed';

  // Initialize platform configuration based on selection
  useEffect(() => {
   
  }, []);

  // Handle resizing the video element
  useEffect(() => {
    const handleResize = () => {
      if (videoRef.current) {
        const rect = videoRef.current.getBoundingClientRect();
        // Any additional logic can be handled within CropModal
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial call

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const stopPropagation = (e) => {
    e.stopPropagation();
  }

  const handleCardClick = (e) => {
    if(onSelect) {
      onSelect();
    }
  }

  // Handle opening and closing the modals
  const handleTrimClick = () => {
    setShowTrimModal(true);
  };

  const handleTrimClose = () => {
    setShowTrimModal(false);
  };

  const handleCropClick = () => {
    setShowCropModal(true);
  };

  const handleCropClose = () => {
    setShowCropModal(false);
  };

  const handleEdit = () => {
    setShowEditModal(true);
  };

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleClose = () => {
    setShowEditModal(false);
    setError('');
  };

  const handleTitleChange = (e) => {
    setNewTitle(e.target.value);
  };

  // Handle saving the new title
  const handleSave = async () => {
    if (newTitle.trim() === '') {
      setError('Title cannot be empty.');
      return;
    }
    setLoading(true);
    try {
      await api.put(`/api/v1/videos/${video.id}/edit-name`, { newTitle });
      video.title = newTitle;
      setNewTitle(newTitle);
      await dispatch(fetchUserPurchasedVideos(user.id));
      handleClose();
      toast.success('Video title updated successfully!');
    } catch (error) {
      console.error('Error updating video title:', error);
      setError('Failed to update video title. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const isDefaultTitle = (title) => {
    const defaultTitlePattern = /^.+_video_\d{8}_\d{6}\.mp4$/;
    return defaultTitlePattern.test(title);
  };

  const getDisplayTitle = () => {
    if (video.title && !isDefaultTitle(video.title.trim())) {
      return video.title;
    } else {
      const courtName = getCourtName(video.title);
      if (video.duration > 900) {
        return `${courtName} - ${formatDate2(video.startTime)} Duration: (${formatDuration(video.duration)})`;
      } else {
        return `${courtName} - ${formatDate(video.startTime)} - ${formatDateExtractTime(video.endTime)} (${formatDuration(video.duration)})`;
      }
    }
  };

  const getCourtName = (title) => {
    if (!title) return 'Unknown Court';

    // Match titles like '123e4567_WaterlooCourt_1_video_'
    const match = title.match(/^.*?_(.*?)_video_/);
    if (match && match[1]) {
      return match[1];
    }

    return 'Unknown Court';
  };

  // Handle video download
  const handleDownloadClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (!video.isPurchased) {
        toast.warn('Please purchase this video to download.');
        return;
      }
      if (!isDownloadInProgress) {
        dispatch(downloadVideo(video.id));
      }
    },
    [dispatch, video.id, video.isPurchased, isDownloadInProgress]
  );

  // Handle video playback
  const handlePlayButtonClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (video.isPurchased) {
        const videoElement = videoRef.current;
        if (videoElement) {
          if (videoElement.paused) {
            videoElement
              .play()
              .then(() => {
                console.log('Video started playing.');
              })
              .catch((error) => {
                console.error('Error trying to play the video:', error);
              });
          } else {
            videoElement.pause();
          }
        }
      } else {
        toast.warn('Please purchase this video to play.');
      }
    },
    [video.isPurchased]
  );

  // Update isPlaying state based on video events
  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    const handlePlay = () => {
      setIsPlaying(true);
    };
    const handlePause = () => {
      setIsPlaying(false);
    };
    const handleEnded = () => {
      setIsPlaying(false);
    };

    videoElement.addEventListener('play', handlePlay);
    videoElement.addEventListener('pause', handlePause);
    videoElement.addEventListener('ended', handleEnded);

    return () => {
      videoElement.removeEventListener('play', handlePlay);
      videoElement.removeEventListener('pause', handlePause);
      videoElement.removeEventListener('ended', handleEnded);
    };
  }, []);

  // Handle video deletion
  const handleDelete = async () => {
    try {
      await api.delete(`/api/v1/videos/${video.id}`);
      toast.success('Video deleted successfully.');
      await dispatch(fetchUserPurchasedVideos(user.id));
      handleCloseDeleteModal();
    } catch (error) {
      console.error('Error deleting video:', error);
      toast.error('Failed to delete video. Please try again.');
    }
  };

  return (
    <Card onClick={handleCardClick}>
      <CheckboxContainer>
        <TooltipWrapper>
          <input
            type="checkbox"
            checked={isSelected}
            onChange={onSelect}
            aria-label={`Select video ${video.id}`}
          />
          <TooltipText>Select this video</TooltipText>
        </TooltipWrapper>
      </CheckboxContainer>

      <ThumbnailContainer>
        <StyledVideo
          ref={videoRef}
          muted
          preload="metadata"
          controls
          playsInline
          poster={video.thumbnails?.[Object.keys(video.thumbnails)[0]] || '/fallback-thumbnail.png'}
          style={{ width: '100%', height: 'auto' }}
          onClick={stopPropagation} // Prevent card click when clicking on video
        >
          <source src={video.preSignedS3Url} type="video/mp4" />
          Your browser does not support the video tag.
        </StyledVideo>
        {!video.isPurchased && <PurchaseOverlay>Purchase Required</PurchaseOverlay>}

        {video.isPurchased && !isPlaying && (
          <PlayButton onClick={(e) => {stopPropagation(e); handlePlayButtonClick(e)}} aria-label="Play video">
            <FaPlay size={20} color="#fff" />
          </PlayButton>
        )}

        {video.isPurchased && (
          <DownloadIconContainer
          onClick={(e) => { stopPropagation(e); handleDownloadClick(e); }}
            role="button"
            tabIndex={0}
            aria-label={`Download video ${video.id}`}
            isDownloadStarted={isDownloadInProgress}
            aria-disabled={isDownloadInProgress}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleDownloadClick(e);
              }
            }}
          >
            {isDownloadInProgress ? (
              <SpinnerIcon aria-label="Downloading" />
            ) : (
              <FaDownload size={20} color="#FFFFFF" />
            )}
          </DownloadIconContainer>
        )}
      </ThumbnailContainer>

      <Content>
        <TitleContainer>
          <Title isPurchased={video.isPurchased}>{getDisplayTitle()}</Title>
          {video.isPurchased && (
            <ButtonGroup>
              <EditButton onClick={(e) => { e.stopPropagation(e); handleEdit(e)}} aria-label="Edit video name">
                Edit name
              </EditButton>
              <TrimButton onClick={(e) => { stopPropagation(e); handleTrimClick(e); }} aria-label="Trim video">
                Trim
              </TrimButton>
              <TrimButton style={{background: '#5a7684'} } onClick={(e) => { stopPropagation(e); handleCropClick(e); }} aria-label="Crop video">
                <FaCrop style={{ marginRight: '5px'}} />
                Crop
              </TrimButton>
              <DeleteButton onClick={() => setShowDeleteModal(true)} aria-label="Delete video">
                Delete
              </DeleteButton>
            </ButtonGroup>
          )}
        </TitleContainer>

        {/* Trim Modal */}
        <Modal
          show={showTrimModal}
          onHide={handleTrimClose}
          centered
          backdrop="static"
          keyboard={false}
          size="md"
        >
          <StyledTrimModalHeader closeButton>
            <Modal.Title>Trim Video</Modal.Title>
          </StyledTrimModalHeader>
          <StyledTrimModalBody>
            <VideoTrimmer video={video} onClose={handleTrimClose} />
          </StyledTrimModalBody>
        </Modal>

        {/* Crop Modal */}
        {showCropModal && (
  <CropModal
    video={video}
    show={showCropModal}
    onClose={handleCropClose}
  />
)}

        {/* Edit Modal */}
        <Modal
          show={showEditModal}
          onHide={handleClose}
          centered
          backdrop="static"
          keyboard={false}
        >
          <StyledModalHeader closeButton>
            <Modal.Title>Edit Video Title</Modal.Title>
          </StyledModalHeader>
          <StyledModalBody>
            <Form>
              <Form.Group controlId="formVideoTitle">
                <Form.Label>Video Title</Form.Label>
                <Form.Control
                  type="text"
                  value={newTitle}
                  onChange={handleTitleChange}
                  placeholder="Enter new video title"
                  autoFocus
                  aria-label="Video title input"
                />
              </Form.Group>
              {error && <ErrorText role="alert">{error}</ErrorText>}
            </Form>
          </StyledModalBody>
          <StyledModalFooter>
            <Button variant="secondary" onClick={handleClose} aria-label="Cancel editing title">
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSave} disabled={loading} aria-label="Save new title">
              {loading ? 'Saving...' : 'Save'}
            </Button>
          </StyledModalFooter>
        </Modal>

        {/* Delete Confirmation Modal */}
        {showDeleteModal && (
          <ModalOverlay>
            <ModalContainer role="dialog" aria-modal="true" aria-labelledby="delete-modal-title">
              <ModalTitle id="delete-modal-title">Confirm Deletion</ModalTitle>
              <ModalBody>
                Are you sure you want to delete this video? This action cannot be undone.
              </ModalBody>
              <ModalActions>
                <CancelButton onClick={handleCloseDeleteModal} aria-label="Cancel deletion">
                  Cancel
                </CancelButton>
                <ConfirmButton onClick={handleDelete} aria-label="Confirm deletion">
                  Delete
                </ConfirmButton>
              </ModalActions>
            </ModalContainer>
          </ModalOverlay>
        )}

        <Description>{video.description}</Description>

        {isDownloadInProgress && (
          <ProgressWrapper aria-label={`Download progress: ${downloadProgress}%`}>
            <ProgressBar style={{ width: `${downloadProgress}%` }} />
            <ProgressLabel>{`${downloadProgress}%`}</ProgressLabel>
          </ProgressWrapper>
        )}

        {isDownloadSuccessful && <DownloadCompleteLabel>Download Complete</DownloadCompleteLabel>}
        {isDownloadFailed && <ErrorMessage>Download failed. Please try again.</ErrorMessage>}
        {!video.isPurchased && <Message>Please purchase this video to access more features.</Message>}
      </Content>
    </Card>
  );
};

VideoCard.propTypes = {
  video: PropTypes.shape({
    id: PropTypes.number.isRequired,
    preSignedS3Url: PropTypes.string.isRequired,
    uploadDate: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    description: PropTypes.string,
    thumbnails: PropTypes.objectOf(PropTypes.string),
    isPurchased: PropTypes.bool,
    title: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }).isRequired,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default React.memo(VideoCard);