// src/components/Policies/PrivacyPolicy.js

import React, { useEffect } from 'react';

const PrivacyPolicy = () => {
  useEffect(() => {
    if (!document.getElementById('termly-jssdk')) {
      const script = document.createElement('script');
      script.id = 'termly-jssdk';
      script.type = 'text/javascript';
      script.src = 'https://app.termly.io/embed-policy.min.js';
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  return (
    <div>
      <div
        name="termly-embed"
        data-id="13d638fb-9167-4a7b-8b68-eb366e975d10"
      ></div>
    </div>
  );
};

export default PrivacyPolicy;
