// src/components/Video/CropModal.js

import React, { useEffect, useState, useRef } from 'react';
import { Modal, ProgressBar, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FaDownload, FaShareAlt, FaCut } from 'react-icons/fa';
import { Rnd } from 'react-rnd';
import { toast } from 'react-toastify';
import api from '../../utils/api';
import { createCropParams } from '../../utils/CropParams';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/userSlice';
import { theme } from '../../components/common/theme';
import { PlatformConfigs, SocialMediaPlatform } from '../../utils/PlatformConfigs';

const CropModal = ({ video, show, onClose }) => {
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const [playerDisplayDimensions, setPlayerDisplayDimensions] = useState({ width: 0, height: 0 });
  const [crop, setCrop] = useState({ x: 0, y: 0, width: 0, height: 0 });
  const [croppedVideoUrl, setCroppedVideoUrl] = useState(null);
  const [cropError, setCropError] = useState(null);
  const [cropLoading, setCropLoading] = useState(false);
  const [trackingId, setTrackingId] = useState(null);
  const [progress, setProgress] = useState(0);
  const [pollingIntervalId, setPollingIntervalId] = useState(null);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [selectedPlatform, setSelectedPlatform] = useState('FREE_CROP'); // New state for selected platform

  // Log state changes for debugging
  useEffect(() => {
    console.log('croppedVideoUrl updated:', croppedVideoUrl);
    console.log('cropLoading updated:', cropLoading);
  }, [croppedVideoUrl, cropLoading]);

  // Handle video container resizing
  useEffect(() => {
    const handleResize = () => {
      if (videoRef.current) {
        const rect = videoRef.current.getBoundingClientRect();
        setPlayerDisplayDimensions({ width: rect.width, height: rect.height });
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial call

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Initialize crop area when video dimensions or platform changes
  useEffect(() => {
    if (playerDisplayDimensions.width > 0) {
      initializeCropArea();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerDisplayDimensions, selectedPlatform]);

  // Start polling when trackingId is set
  useEffect(() => {
    if (trackingId) {
      startPolling();
    }

    // Cleanup polling on unmount
    return () => {
      if (pollingIntervalId) {
        clearInterval(pollingIntervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackingId]);

  // Initialize Crop Area based on selected platform
  const initializeCropArea = () => {
    const { width: displayedWidth, height: displayedHeight } = playerDisplayDimensions;

    if (selectedPlatform === 'FREE_CROP') {
      // Free Crop: 80% of video dimensions
      const cropWidth = displayedWidth * 0.8;
      const cropHeight = displayedHeight * 0.8;
      const cropX = (displayedWidth - cropWidth) / 2;
      const cropY = (displayedHeight - cropHeight) / 2;

      setCrop({
        x: cropX,
        y: cropY,
        width: cropWidth,
        height: cropHeight,
      });
    } else {
      // Predefined Crop: Based on selected platform
      const config = PlatformConfigs.find((config) => config.value === selectedPlatform);

      if (config) {
        const aspectRatio = config.aspectRatio;

        // Determine crop dimensions within video display
        let cropWidth = config.maxWidth;
        let cropHeight = config.maxHeight;

        // Adjust to fit within the displayed video dimensions
        if (cropWidth > displayedWidth) {
          cropWidth = displayedWidth;
          cropHeight = cropWidth * aspectRatio;
        }
        if (cropHeight > displayedHeight) {
          cropHeight = displayedHeight;
          cropWidth = cropHeight / aspectRatio;
        }

        const cropX = (displayedWidth - cropWidth) / 2;
        const cropY = (displayedHeight - cropHeight) / 2;

        setCrop({
          x: cropX,
          y: cropY,
          width: cropWidth,
          height: cropHeight,
        });
      } else {
        // Fallback to free crop
        const cropWidth = displayedWidth * 0.8;
        const cropHeight = displayedHeight * 0.8;
        const cropX = (displayedWidth - cropWidth) / 2;
        const cropY = (displayedHeight - cropHeight) / 2;

        setCrop({
          x: cropX,
          y: cropY,
          width: cropWidth,
          height: cropHeight,
        });
      }
    }
  };

  // Handle platform selection change
  const handlePlatformChange = (e) => {
    const selected = e.target.value;
    setSelectedPlatform(selected);
  };

  // Handle Crop Action
  const handleCrop = async () => {
    setCropError(null);
    setCropLoading(true);
    setCroppedVideoUrl(null);
    setProgress(0);

    const videoElement = videoRef.current;
    if (videoElement) {
      const actualWidth = videoElement.videoWidth;
      const actualHeight = videoElement.videoHeight;

      const { width: displayedWidth, height: displayedHeight } = playerDisplayDimensions;

      if (displayedWidth === 0 || displayedHeight === 0) {
        setCropError('Video dimensions not available.');
        setCropLoading(false);
        return;
      }

      const scaleX = actualWidth / displayedWidth;
      const scaleY = actualHeight / displayedHeight;

      const mappedX = Math.round(crop.x * scaleX);
      const mappedY = Math.round(crop.y * scaleY);
      const mappedWidth = Math.round(crop.width * scaleX);
      const mappedHeight = Math.round(crop.height * scaleY);

      const cropParams = createCropParams(mappedX, mappedY, mappedWidth, mappedHeight, 'CUSTOM');

      try {
        dispatch(setLoading(true));
        const response = await api.post(`/videos/${video.id}/crop`, cropParams);
        console.log('Crop initiated. Tracking ID:', response.data);

        setTrackingId(response.data);
      } catch (err) {
        console.error('Error initiating crop:', err);
        setCropError('Error initiating crop. Please try again.');
        setCropLoading(false);
        dispatch(setLoading(false));
      }
    } else {
      setCropError('Video element not found.');
      setCropLoading(false);
    }
  };

  // Start Polling for Crop Status
  const startPolling = () => {
    const intervalId = setInterval(async () => {
      try {
        const statusResponse = await api.get('/videos/crop/status', {
          params: { trackingId },
        });
        console.log('Status Response:', statusResponse.data);

        const processingInfo = statusResponse.data;

        if (processingInfo.videoProcessingStatus === 'PROCESSED') {
          setCropLoading(false);
          dispatch(setLoading(false));
          clearInterval(intervalId);

          if (processingInfo.preSignedUrl) {
            console.log('Cropped video URL:', processingInfo.preSignedUrl);
            setCroppedVideoUrl(processingInfo.preSignedUrl);
            toast.success('Video cropped successfully!');
          } else {
            setCropError('Cropped video URL not available.');
          }
        } else if (processingInfo.videoProcessingStatus === 'FAILED') {
          setCropLoading(false);
          dispatch(setLoading(false));
          clearInterval(intervalId);
          setCropError(`Video cropping failed: ${processingInfo.videoProcessingErrorMessage}`);
          toast.error('Video cropping failed.');
        } else {
          // Update progress if needed
          console.log(
            `Processing status: ${processingInfo.videoProcessingStatus}, progress: ${processingInfo.videoProcessingProgress}%`
          );
          setProgress(processingInfo.videoProcessingProgress);
        }
      } catch (err) {
        console.error('Error fetching crop status:', err);
        setCropError('Error fetching crop status.');
        setCropLoading(false);
        dispatch(setLoading(false));
        clearInterval(intervalId);
      }
    }, 5000); // Poll every 5 seconds

    setPollingIntervalId(intervalId);
  };

  // Handle Download Action
  const handleDownloadCroppedVideo = async () => {
    if (!croppedVideoUrl) {
      setCropError('Cropped video URL is not available.');
      return;
    }
    setCropLoading(true);
    setCropError('');
    setDownloadProgress(0);

    try {
      const response = await fetch(croppedVideoUrl);
      if (!response.ok) {
        throw new Error('Failed to download the cropped video.');
      }

      const contentLength = response.headers.get('Content-Length');
      if (!contentLength) {
        throw new Error('Content-Length header is missing.');
      }

      const total = parseInt(contentLength, 10);
      let loaded = 0;
      const reader = response.body.getReader();
      const chunks = [];

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        chunks.push(value);
        loaded += value.length;
        const downloadProgress = Math.floor((loaded / total) * 100);
        setDownloadProgress(downloadProgress);
      }

      const blob = new Blob(chunks, { type: 'video/mp4' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `cropped_${video.id}.mp4`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      setCropLoading(false);
      toast.success('Cropped video downloaded successfully.');
    } catch (error) {
      console.error('Error downloading the cropped video:', error);
      setCropError('Failed to download the cropped video. Please try again.');
      setCropLoading(false);
    }
  };

  // Handle Share Action
  const handleShareCroppedVideo = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: `Cropped Video - ${video.title}`,
          text: 'Check out this video I cropped!',
          url: croppedVideoUrl,
        });
      } else {
        toast.info('Your browser does not support sharing.');
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  // Handle Trim Action
  const handleTrimCroppedVideo = () => {
    toast.info('Trimming cropped video feature is under development.');
  };

  // Cleanup the object URL when the component unmounts
  useEffect(() => {
    return () => {
      if (croppedVideoUrl) {
        window.URL.revokeObjectURL(croppedVideoUrl);
      }
    };
  }, [croppedVideoUrl]);

  return (
    <StyledModal
      show={show}
      onHide={onClose}
      size="lg"
      aria-labelledby="crop-modal-title"
      centered
      backdrop="static"
      keyboard={false}
      scrollable
    >
      {!croppedVideoUrl ? (
        <>
          <Modal.Header closeButton>
            <Modal.Title id="crop-modal-title">Crop Video</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Platform Selection Dropdown */}
            <Form.Group controlId="platformSelect" style={{ marginBottom: '1rem' }}>
              <Form.Label>Select Crop Dimension</Form.Label>
              <Form.Control as="select" value={selectedPlatform} onChange={handlePlatformChange}>
                <option value="FREE_CROP">Free Crop</option>
                {PlatformConfigs.map((config) => (
                  <option key={config.value} value={config.value}>
                    {config.label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <VideoContainer>
              <StyledVideo
                ref={videoRef}
                muted
                preload="metadata"
                controls
                playsInline
                src={video.preSignedS3Url}
              >
                Your browser does not support the video tag.
              </StyledVideo>

              {crop.width > 0 && crop.height > 0 && (
                <StyledRnd
                  size={{ width: crop.width, height: crop.height }}
                  position={{ x: crop.x, y: crop.y }}
                  bounds="parent"
                  minWidth={50}
                  minHeight={50}
                  maxWidth={playerDisplayDimensions.width}
                  maxHeight={playerDisplayDimensions.height}
                  enableResizing={
                    selectedPlatform === 'FREE_CROP'
                      ? {
                          top: true,
                          right: true,
                          bottom: true,
                          left: true,
                          topRight: true,
                          bottomRight: true,
                          bottomLeft: true,
                          topLeft: true,
                        }
                      : {
                          top: true,
                          right: true,
                          bottom: true,
                          left: true,
                          topRight: true,
                          bottomRight: true,
                          bottomLeft: true,
                          topLeft: true,
                        }
                  }
                  lockAspectRatio={selectedPlatform !== 'FREE_CROP'} // Lock aspect ratio if a platform is selected
                  aspectRatio={
                    selectedPlatform !== 'FREE_CROP'
                      ? PlatformConfigs.find((config) => config.value === selectedPlatform)?.aspectRatio
                      : undefined
                  }
                  onDragStop={(e, data) => {
                    setCrop((prev) => ({
                      ...prev,
                      x: data.x,
                      y: data.y,
                    }));
                  }}
                  onResizeStop={(e, direction, ref, delta, position) => {
                    setCrop({
                      x: position.x,
                      y: position.y,
                      width: parseFloat(ref.style.width),
                      height: parseFloat(ref.style.height),
                    });
                  }}
                />
              )}
            </VideoContainer>
            {progress > 0 && (
              <StyledProgressBar
                now={progress}
                label={`${progress}%`}
                style={{ marginTop: '1rem' }}
                variant="info"
              />
            )}
            {cropError && <ErrorText role="alert">{cropError}</ErrorText>}
          </Modal.Body>
          <Modal.Footer>
            <ActionButton
              onClick={onClose}
              bgColor={theme.colors.darkText}
              hoverColor={theme.colors.darkBackground}
              aria-label="Cancel cropping"
            >
              Cancel
            </ActionButton>
            <ActionButton
              onClick={handleCrop}
              disabled={cropLoading}
              bgColor={theme.colors.secondary}
              hoverColor={theme.colors.accents[1]}
              aria-label="Crop video"
            >
              {cropLoading ? 'Cropping...' : 'Crop Video'}
            </ActionButton>
          </Modal.Footer>
        </>
      ) : (
        <>
          <Modal.Header closeButton>
            <Modal.Title id="cropped-video-title">Cropped Video</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CroppedVideoContainer>
              <StyledVideo src={croppedVideoUrl} controls />
              {downloadProgress > 0 && (
                <StyledProgressBar
                  now={downloadProgress}
                  label={`${downloadProgress}%`}
                  style={{ marginTop: '1rem' }}
                  variant="success"
                />
              )}
            </CroppedVideoContainer>
            {cropError && <ErrorText role="alert">{cropError}</ErrorText>}
          </Modal.Body>
          <Modal.Footer>
            <ActionButton
              onClick={handleDownloadCroppedVideo}
              disabled={cropLoading}
              bgColor={theme.colors.secondary}
              hoverColor={theme.colors.darkBackground}
              aria-label="Download cropped video"
            >
              {cropLoading ? (
                'Downloading...'
              ) : (
                <>
                  <FaDownload style={{ marginRight: '5px' }} />
                  Download
                </>
              )}
            </ActionButton>
            <ActionButton
              onClick={handleTrimCroppedVideo}
              bgColor={theme.colors.accents[1]}
              hoverColor={theme.colors.darkBackground}
              aria-label="Trim cropped video"
            >
              <FaCut style={{ marginRight: '5px' }} />
              Trim
            </ActionButton>
            <ActionButton
              onClick={handleShareCroppedVideo}
              bgColor={theme.colors.accents[2]}
              hoverColor={theme.colors.darkBackground}
              aria-label="Share cropped video"
            >
              <FaShareAlt style={{ marginRight: '5px' }} />
              Share
            </ActionButton>
            <ActionButton
              onClick={onClose}
              bgColor={theme.colors.textPrimary}
              hoverColor={theme.colors.darkBackground}
              aria-label="Close"
            >
              Close
            </ActionButton>
          </Modal.Footer>
        </>
      )}
    </StyledModal>
  );
};

CropModal.propTypes = {
  video: PropTypes.shape({
    id: PropTypes.number.isRequired,
    preSignedS3Url: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CropModal;

// Styled Components with Enhanced Styling and Responsiveness

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: ${({ theme }) => theme.colors.secondaryBackground};
    border-radius: ${({ theme }) => theme.spacing.md};
    overflow: hidden;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    .modal-dialog {
      margin: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.xs};
    }

    .modal-content {
      padding: ${({ theme }) => theme.spacing.sm};
    }
  }
`;

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  border-radius: ${({ theme }) => theme.spacing.sm};
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding-top: 75%; /* Adjust for smaller screens */
  }
`;

const CroppedVideoContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  background-color: ${({ theme }) => theme.colors.primaryBackground};
  border-radius: ${({ theme }) => theme.spacing.sm};
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding-top: 75%; /* Adjust for smaller screens */
  }
`;

const StyledVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.spacing.sm};
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
`;

const StyledRnd = styled(Rnd)`
  .react-draggable {
    z-index: 1000;
  }

  .react-resizable-handle {
    width: 10px;
    height: 10px;
    background-color: ${({ theme }) => theme.colors.accent};
  }

  border: 2px solid ${({ theme }) => theme.colors.accent};
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.md};
  background-color: ${({ bgColor }) => bgColor || theme.colors.primary};
  border: none;
  border-radius: ${({ theme }) => theme.spacing.sm};
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.md};
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.button};
  min-width: 100px;

  &:hover {
    background-color: ${({ hoverColor }) => hoverColor || theme.colors.darkBackground};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabledBackground};
    cursor: not-allowed;
  }

  svg {
    margin-right: 5px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.sm};
    font-size: ${({ theme }) => theme.fontSizes.sm};

    svg {
      margin-right: 3px;
    }

    min-width: 80px;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-top: ${({ theme }) => theme.spacing.md};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xs};
  }
`;

const ErrorText = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  margin-top: ${({ theme }) => theme.spacing.sm};
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme }) => theme.fontSizes.xs};
  }
`;

const StyledProgressBar = styled(ProgressBar)`
  .progress-bar {
    background-color: ${({ variant, theme }) =>
      variant === 'info'
        ? theme.colors.teal
        : variant === 'success'
        ? theme.colors.success
        : theme.colors.error} !important;
  }

  height: 10px;
  border-radius: ${({ theme }) => theme.spacing.xs};
  margin-top: ${({ theme }) => theme.spacing.sm};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    height: 8px;
  }
`;
