// src/components/Policies/Disclaimer.js

import React, { useEffect } from 'react';

const Disclaimer = () => {
  useEffect(() => {
    if (!document.getElementById('termly-jssdk')) {
      const script = document.createElement('script');
      script.id = 'termly-jssdk';
      script.type = 'text/javascript';
      script.src = 'https://app.termly.io/embed-policy.min.js';
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  return (
    <div>
      <div
        name="termly-embed"
        data-id="9bc71f49-004a-4414-8a1f-78bd09a6c04b"
      ></div>
    </div>
  );
};

export default Disclaimer;
