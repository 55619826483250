// src/components/Policies/TermsOfService.js

import React, { useEffect } from 'react';

const TermsOfService = () => {
  useEffect(() => {
    if (!document.getElementById('termly-jssdk')) {
      const script = document.createElement('script');
      script.id = 'termly-jssdk';
      script.type = 'text/javascript';
      script.src = 'https://app.termly.io/embed-policy.min.js';
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  return (
    <div>
      <div
        name="termly-embed"
        data-id="bfaba905-3b80-44c6-aadb-ba01fda339ac"
      ></div>
    </div>
  );
};

export default TermsOfService;
