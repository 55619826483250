// src/components/common/NavBar.js

import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FaSignOutAlt, FaUserCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux'; // Import useDispatch
import styled from 'styled-components';
import { useAuth } from '../Auth/AuthProvider';
import Logo from './Logo';
import { COLORS } from '../../BrandColors';
import { setSelectedCourt } from '../../store/videoSlice'; // Import setSelectedCourt

const NavBar = () => {
  const { user, logout } = useAuth();

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
  };

  const isAdmin = user && user.roles && user.roles.includes('ADMIN');

  return (
    <StyledNavbar fixed="top" expand="lg" variant="light">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand aria-label="Go to home">
            <Logo size="small" />
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <LinkContainer to="/">
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/explore">
              <Nav.Link>Purchase</Nav.Link>
            </LinkContainer>
            <LinkContainer to="/contact">
              <Nav.Link>Contact</Nav.Link>
            </LinkContainer>
            {user && (
              <LinkContainer to={isAdmin ? "/admin/dashboard" : "/dashboard"}>
                <Nav.Link>{isAdmin ? 'Admin Dashboard' : 'Dashboard'}</Nav.Link>
              </LinkContainer>
            )}
          </Nav>
          <Nav className="ms-auto align-items-center">
            {user ? (
              <>
                <UserIcon aria-label="User Profile">
                  <FaUserCircle />
                </UserIcon>
                <LogoutIcon onClick={handleLogout} aria-label="Logout">
                  <FaSignOutAlt />
                </LogoutIcon>
              </>
            ) : (
              <LinkContainer to="/login">
                <LoginButton>Login</LoginButton>
              </LinkContainer>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </StyledNavbar>
  );
};

export default NavBar;

// Styled Components


const StyledNavbar = styled(Navbar)`
  background-color: ${COLORS.primaryBackground} !important; /* Override Bootstrap default */
  border-bottom: 1px solid #e0e0e0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  // padding: 35px 0 20px 0;
  
  z-index: 1030; /* Higher than Bootstrap's default (1020) */

  .navbar-brand {
    display: flex;
    align-items: center;
    padding: 13px 15px;
    /* Additional styling if needed */
  }

  .nav-link {
    font-family: 'Raleway', sans-serif;
    color: #010101 !important;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    lts: 1px;
    position: relative;
    transition: color 0.3s ease;

    &:hover {
      background: none;
      color: ${COLORS.accentTeal} !important;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -5px;
      width: 0%;
      height: 2px;
      background-color: ${COLORS.secondaryButton};
      transition: width 0.3s ease;
    }

    &:hover::after {
      width: 100%;
    }
  }

  .navbar-toggler {
    border-color: ${COLORS.accentBlueGray};

    &:focus {
      outline: 3px solid ${COLORS.accentTeal};
      outline-offset: 2px;
    }
  }

  .navbar-collapse {
    @media (max-width: 768px) {
      background-color: #F9F9F9;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }
  }
`;

const LoginButton = styled.button`
  font-family: 'Raleway', sans-serif;
  padding: 8px 16px;
  background-color: ${COLORS.secondaryButton};
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 13px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${COLORS.accentOrange};
  }

  &:focus {
    outline: 3px solid ${COLORS.accentTeal};
    outline-offset: 2px;
  }

  @media (max-width: 768px) {
    padding: 6px 12px;
    font-size: 14px;
  }
`;

const LogoutButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: ${COLORS.secondaryButton};
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s ease;
  width: 100%;
  padding: 10px 0;

  &:hover {
    color: ${COLORS.accentOrange};
  }

  &:focus {
    outline: 3px solid ${COLORS.accentTeal};
    outline-offset: 2px;
  }

  svg {
    margin-right: 8px;
  }
`;

const LocationSelect = styled.select`
  padding: 8px 12px;
  font-size: 16px;
  font-weight: bold;
  background-color: #f0f0f0;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-right: 20px;
  cursor: pointer;
  color: #010101;

  @media (max-width: 768px) {
    margin-right: 10px;
    flex: 1;
  }

  &:focus {
    outline: 3px solid ${COLORS.accentTeal};
    outline-offset: 2px;
  }
`;

const UserIcon = styled(FaUserCircle)`
  font-size: 28px;
  color: ${COLORS.accentBlueGray};
  margin-right: 20px;

  @media (max-width: 768px) {
    margin-right: 10px;
  }

  &:hover {
    color: ${COLORS.accentTeal};
  }

  cursor: pointer;

  &:focus {
    outline: 3px solid ${COLORS.accentTeal};
    outline-offset: 2px;
  }
`;


const LogoutIcon = styled(FaSignOutAlt)`
  font-size: 1.5rem;
  color: ${COLORS.secondaryButton};
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: ${COLORS.accentOrange};
  }

  &:focus {
    outline: 3px solid ${COLORS.accentTeal};
    outline-offset: 2px;
  }

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 1.25rem;
  }

  @media (${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 1rem;
  }
`;
