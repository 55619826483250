// src/utils/PlatformConfigs.js

export const SocialMediaPlatform = {
    INSTAGRAM_POST: 'INSTAGRAM_POST',
    FACEBOOK_POST: 'FACEBOOK_POST',
    LINKEDIN_POST: 'LINKEDIN_POST',
    X_POST: 'X_POST',
    INSTAGRAM_STORY: 'INSTAGRAM_STORY',
    FACEBOOK_STORY: 'FACEBOOK_STORY',
    LINKEDIN_STORY: 'LINKEDIN_STORY',
    X_STORY: 'X_STORY',
  };
  
  export const PlatformConfigs = [
    { label: 'Instagram Post (1080x1080)',
       value: SocialMediaPlatform.INSTAGRAM_POST,
        maxWidth: 1080, 
        maxHeight: 1080,
       aspectRatio: 1 },
    { label: 'Facebook Post (1080x1080)', value: SocialMediaPlatform.FACEBOOK_POST, maxWidth: 1080, maxHeight: 1080, aspectRatio: 1 },
    { label: 'LinkedIn Post (1080x1080)', value: SocialMediaPlatform.LINKEDIN_POST, maxWidth: 1080, maxHeight: 1080, aspectRatio: 1 },
    { label: 'X Post (1080x1080)', value: SocialMediaPlatform.X_POST, maxWidth: 1080, maxHeight: 1080, aspectRatio: 1 },
    { label: 'Instagram Story (1080x1920)', value: SocialMediaPlatform.INSTAGRAM_STORY, maxWidth: 1080, maxHeight: 1920, aspectRatio: 0.5625 },
    { label: 'Facebook Story (1080x1920)', value: SocialMediaPlatform.FACEBOOK_STORY, maxWidth: 1080, maxHeight: 1920, aspectRatio: 0.5625 },
    { label: 'LinkedIn Story (1080x1920)', value: SocialMediaPlatform.LINKEDIN_STORY, maxWidth: 1080, maxHeight: 1920, aspectRatio: 0.5625 },
    { label: 'X Story (1080x1920)', value: SocialMediaPlatform.X_STORY, maxWidth: 1080, maxHeight: 1920, aspectRatio: 0.5625 },
  ];
  